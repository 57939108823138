<template>
  <div>
    <b-modal
      id="AddCompany"
      ref="AddCompany"
      scrollable
      no-close-on-backdrop
      hide-header-close
      size="lg"
    >
      <template #modal-footer="{}">
        <b-button size="sm" variant="outline-primary" @click="cancel">
          {{ $t("back") }}
        </b-button>
        <b-button size="sm" variant="primary" @click="submit">
          {{ $t("save") }}
        </b-button>
      </template>
      <h3>{{ $t("add_new_debtor") }} </h3>
      <validation-observer
        ref="companyRegisterValidation"
        name="RegisterCompany"
      >
        <h5>{{ $t("company_name") }}</h5>
        <b-form>
          <b-card class="col-md-12">
            <b-row m-0 p-0>
              <b-col cols="12" xl="12" md="12" lg="12">
                <b-form-group
                  id="domicile-country-group"
                  :label="$t('domicile_country') + ':'"
                  label-for="domicile-country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-select
                      id="domicile-country"
                      v-model="country_id"
                      :options="countries"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  id="company-name-group"
                  :label="$t('company_name') + ':'"
                  label-for="company-name"
                >
                  <vue-autosuggest
                    :suggestions="filteredOptions"
                    :limit="10"
                    v-model="company_name"
                    required
                    :disabled="true"
                    id="company-name"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: 'form-control',
                      placeholder: $t('select_company'),
                    }"
                    @selected="onSelected"
                    :get-suggestion-value="getSuggestionValue"
                    @keyup="searchForCompaniesName"
                  >
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">
                        {{ suggestion.item }}
                      </span>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                {{ $t("company_uid") }}:
                <b>{{
                  companies[company_name]
                    ? companies[company_name]
                    : company_uid
                }}</b>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-button
                  variant="primary"
                  @click="getCompanyDataFromZefix()"
                  v-if="companies[company_name] != null"
                >
                  <feather-icon icon="DownloadIcon" /> {{ $t("get_data") }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <h5>{{ $t("company_information") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="5" md="6" lg="6">
                <b-form-group
                  id="company-name-field-group"
                  :label="$t('company_name') + ':'"
                  label-for="company-name-field"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HomeIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="company-name-field"
                        v-model="company_name_after"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group
                  id="company-address-group"
                  :label="$t('company-address') + ':'"
                  label-for="company-address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Address"
                    rules="required"
                  >
                    <b-form-input
                      id="company-address"
                      v-model="company_address"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="3" md="2" lg="2">
                <b-form-group
                  id="company-address-nr-group"
                  :label="$t('company-address-nr') + ':'"
                  label-for="company-address-nr"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Address Nr"
                    rules="required"
                  >
                    <b-form-input
                      id="company-address-nr"
                      v-model="company_address_nr"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-uid-group"
                  :label="
                    $t('company_uid ') + '(CHE-397.517.219 > CHE397517219):'
                  "
                  label-for="company-uid"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company UID"
                    rules="required"
                  >
                    <b-form-input
                      id="company-uid"
                      v-model="company_uid"
                      required
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group
                  id="company-zip-group"
                  :label="$t('zip') + ':'"
                  label-for="company-zip"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Zip"
                    rules="required"
                  >
                    <b-form-input
                      id="company-zip"
                      v-model="company_zip"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group
                  id="company-city-group"
                  :label="$t('city') + ':'"
                  label-for="company-city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company City"
                    rules="required"
                  >
                    <b-form-input
                      id="company-city"
                      v-model="company_city"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-legal-form-group"
                  :label="$t('legal_form') + ':'"
                  label-for="company-legal-form"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Legal Form"
                    rules="required"
                  >
                    <b-form-select
                      id="company-legal-form"
                      v-model="company_legal_form"
                      :options="legal_forms"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-website-group"
                  :label="$t('website') + ':'"
                  label-for="company-website"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="GlobeIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="company-website"
                      v-model="company_website"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <h5>{{ $t("main_contact_person") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-gender-group"
                  :label="$t('salutation') + ':'"
                  label-for="main-contact-gender"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-select
                      id="main-contact-gender"
                      v-model="main_contact_gender"
                      :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('mr'), value: '0' },
                        { text: $t('mrs'), value: '1' },
                      ]"
                      required
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-email-group"
                  :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-email"
                        v-model="main_contact_email"
                        required
                        :state="errors.length > 0 ? false : null"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-first-name-group"
                  :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First name"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-first-name"
                        v-model="main_contact_first_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-tel-group"
                  :label="$t('tel') + ':'"
                  label-for="main-contact-tel"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tel"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend id="phoneCountries">
                        <b-form-select
                          :options="phoneCountriesList"
                          v-model="phoneContries"
                        >
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-tel"
                        v-model="main_contact_tel"
                        @input="checkPhoneFormat"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                      >
                      </b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="phoneValidation">{{
                      phoneValidation
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-last-name-group"
                  :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-last-name"
                        v-model="main_contact_last_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-position-group"
                  :label="$t('position_in_the_company') + ':'"
                  label-for="main-contact-position"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Position in the Company"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon
                          icon="BriefcaseIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-position"
                        v-model="main_contact_position"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import { phone } from "phone";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BAlert,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  data() {
    return {
      timeout: null,
      filteredOptions: [],
      showLoader: false,
      disableInputs: false,
      country_id: null,
      countries: [],
      company_id: this.$store.state.auth.user.company_id,
      company_name: "",
      company_name_after: "",
      companies: [],
      company_zefix_data: null,
      company_address: "",
      company_address_nr: "",
      company_uid: "",
      company_zip: "",
      company_city: "",
      company_legal_form: "",
      company_website: "",
      dialog: false,
      legal_forms: [
        "Aktiengesellschaft",
        "Gesellschaft mit beschränkter Haftung",
        "Einzelunternehmen",
      ],
      company_established_at: "",
      number_of_employees: "",
      annual_turnover: "",
      intent_to_sell: "",
      number_of_debtors: "",
      debt_enforcement: "Yes",
      intent_to_sell_values: [
        "1% - 10%",
        "11% - 20%",
        "21% - 30%",
        "31% - 50%",
        "51% - 70%",
        "71% - 80%",
        "81% - 90%",
        "91% - 100%",
      ],
      number_of_debtors_values: [
        "1-10",
        "11-20",
        "21-50",
        "51-100",
        "101-200",
        "201-500",
        "500+",
      ],
      debt_enforcement_values: ["Yes", "No"],
      main_contact_gender: "",
      main_contact_email: "",
      main_contact_first_name: "",
      main_contact_last_name: "",
      main_contact_tel: "",
      main_contact_position: "",
      signing_authority_type: "",
      signing_authority_type_values: ["Sole signatory", "Collectively by two"],
      signators: [
        {
          gender: "",
          first_name: "",
          email: "",
          last_name: "",
          tel: "",
          position: "",
          signing_authority_type: "",
        },
      ],
      terms_and_conditions: "",
      data_privacy: "",
      hideSigners: true,
      successMessage: false,
      showBlueBorder: false,
      phoneContries: "",
      phoneCountriesList: [],
      phoneValidation: "",
    };
  },
  props: ["showDialog", "invoice", "company"],
  name: "AddCompany",
  watch: {
    company(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.company_zefix_data = newValue;
        this.fillWithData();
      }
    },
  },
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.phoneCountriesList.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
        this.countries.push({
          value: item.id,
          text: item.name,
        });
      });
    });

    if (this.$props.company != null) {
      this.company_zefix_data = this.$props.company;
      this.fillWithData();
    }
  },
  methods: {
    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    fillWithData() {
      this.company_uid = this.company_zefix_data.uid;
      this.company_name = this.company_zefix_data.name;
      this.company_name_after = this.company_zefix_data.name;
      this.company_address = this.company_zefix_data.address.street;
      this.company_address_nr = this.company_zefix_data.address.houseNumber;
      this.company_city = this.company_zefix_data.address.city;
      this.company_zip = this.company_zefix_data.address.swissZipCode;
      this.company_legal_form = this.company_zefix_data.legalForm.name.de;
    },
    getCompanyDataFromZefix() {
      this.showLoader = true;
      if (typeof this.companies[this.company_name] != "undefined") {
        this.$http
          .post("/get-company-data-from-zefix", {
            search: this.companies[this.company_name],
          })
          .then((res) => {
            this.company_zefix_data = res.data.value;
            this.company_uid = this.company_zefix_data.uid;
            this.company_name_after = this.company_zefix_data.name;
            this.company_address = this.company_zefix_data.address.street;
            this.company_address_nr =
              this.company_zefix_data.address.houseNumber;
            this.company_city = this.company_zefix_data.address.city;
            this.company_zip = this.company_zefix_data.address.swissZipCode;
            this.company_legal_form = this.company_zefix_data.legalForm.name.de;
            // this.company_established_at = this.company_zefix_data.sogcDate;
            this.showLoader = false;
            this.successMessage = true;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Data received successfully",
                icon: "SuccessIcon",
                variant: "success",
              },
            });
          });
      }
    },
    toggleDarkMode() {
      let color = "";
      if (this.$store.state.verticalMenu.lightMode == "light") {
        color = "background:white";
      } else {
        color = "background:#283046";
      }
      return color;
    },

    saveCompanyInfo() {
      this.$refs.companyRegisterValidation.validate().then((success) => {
        if (success) {
          this.disableInputs = true;
          const formSubmissionData = {
            company_id: this.company_id,
            company_name: this.company_name_after,
            company_address: this.company_address,
            company_address_nr: this.company_address_nr,
            company_uid: this.company_uid,
            zip: this.company_zip,
            company_city: this.company_city,
            company_country_id: this.country_id,
            company_website: this.company_website,
            company_legal_form: this.company_legal_form,
            main_contact_first_name: this.main_contact_first_name,
            main_contact_last_name: this.main_contact_last_name,
            main_contact_position: this.main_contact_position,
            main_contact_tel: this.main_contact_tel,
            main_contact_email: this.main_contact_email,
            main_contact_gender: this.main_contact_gender,
            address: this.company_address,
            invoice_id: this.invoice.id,
            placeholder: 1,
            iso_code: this.phoneContries,
          };

          this.$http.post("/debtor/store", formSubmissionData).then((res) => {
            if (res) {
              if (res.data.value) {
                this.$emit("closeAddCompModal", res.data.value);
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message
                    ? res.data.message
                    : "Something went wrong, please try again",
                },
              });
            }
          });
        }
        this.disableInputs = false;
      });
    },
    deleteSignator(index) {
      this.signators.splice(index, 1);
    },
    onSelected() {
      this.successMessage = false;
    },
    getSuggestionValue(value) {
      this.company_name = value.item;
    },
    searchForCompaniesName() {
      clearTimeout(this.timeout);
      const self = this;
      this.timeout = setTimeout(function () {
        if (self.company_name.length > 2) {
          self.companies = [];
          self.filteredOptions = [];
          self.$http
            .post("/get-companies-name-from-zefix", {
              search: self.company_name,
            })
            .then((res) => {
              self.successMessage = false;
              self.filteredOptions.push({ data: Object.keys(res.data.value) });
              self.companies = res.data.value;
            });
        } else {
          self.companies = [];
          self.filteredOptions = [];
        }
      }, 1000);
    },

    submit() {
      this.saveCompanyInfo();
    },
    cancel() {
      this.$emit("closeAddCompModal", null);
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = 0;
      this.countries = [];
    },
  },
};
</script>

<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>









